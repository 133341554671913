import {
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  unstable_data as dataResponse,
} from '@remix-run/node';
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useFetcher,
  useLoaderData,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import omit from 'lodash/omit';
import React from 'react';
import { useChangeLanguage } from 'remix-i18next/react';
import smartbannerStyles from 'smartbanner.js/dist/smartbanner.min.css?url';
import smartbanner from 'smartbanner.js?url';
import { toast } from 'sonner';
import { useIsMounted } from 'usehooks-ts';
import { Trans, useTranslation } from '~/i18n';
import { AppleStore } from './components/AppleStore';
import {
  Footer,
  FooterAboutColette,
  FooterColette,
  FooterContent,
  FooterHelp,
  FooterMeta,
  FooterServices,
} from './components/Footer';
import { GooglePlayStore } from './components/GooglePlayStore';
import { Header, HeaderNav } from './components/Header';
import { buttonVariants } from './components/ui/Button';
import { Image } from './components/ui/Image';
import { Logo } from './components/ui/Logo';
import { Toaster } from './components/ui/Toaster';
import { HeadingL, HeadingM, HeadingXXL, textVariants } from './components/ui/Typography';
import { APPLE_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from './constants';
import { useRouteActionToasts } from './hooks/use-route-action-data';
import i18next, { localeCookie } from './i18n/i18n.server';
import sonnerStyles from './sonner.css?url';
import styles from './tailwind.css?url';
import { type ViewerSession, getViewerSession } from './utils/auth.server';
import { getEnv } from './utils/env.server';
import 'dayjs/locale/fr';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import { GoogleTagManager } from './components/GoogleTagManager';
import { authenticateByTokenMutation } from './generated/server';
import { SearchParams } from './utils/search-params';
import { leadSourceCookie } from './utils/session.server';

dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale('fr');
dayjs.extend(updateLocale);
dayjs.extend(LocalizedFormat);

dayjs.updateLocale('fr', {
  calendar: {
    lastDay: '[Hier à] LT',
    sameDay: "[Aujourd'hui à] LT",
    nextDay: '[Demain à] LT',
    lastWeek: '[last] dddd [à] LT',
    nextWeek: 'dddd [à] LT',
    sameElse: 'DD MMMM, HH[h]mm',
  },
});

export const meta: MetaFunction<typeof loader> = ({ data }) => data?.meta || [];

export const links: LinksFunction = () => [
  {
    rel: 'preload',
    href: styles,
    as: 'style',
  },
  {
    rel: 'preload',
    href: sonnerStyles,
    as: 'style',
  },
  { rel: 'stylesheet', href: styles },
  {
    rel: 'preload',
    href: smartbannerStyles,
    as: 'style',
    media: 'screen and (max-width: 640px)',
  },
  {
    rel: 'stylesheet',
    href: smartbannerStyles,
    media: 'screen and (max-width: 640px)',
  },
  { rel: 'stylesheet', href: sonnerStyles },
  { rel: 'preconnect', href: 'https://o1218715.ingest.us.sentry.io' },
  { rel: 'preconnect', href: 'https://assets.colette.club' },
  { rel: 'dns-prefetch"', href: 'https://assets.colette.club' },
  { rel: 'icon', href: '/favicon.ico', sizes: '32x32' },
  { rel: 'icon', href: '/icons/icon-128x128.png', sizes: '128x128' },
  { rel: 'icon', href: '/icons/icon-180x180.png', sizes: '180x180' },
  { rel: 'icon', href: '/icons/icon-192x192.png', sizes: '192x192' },
  {
    rel: 'apple-touch-icon',
    href: '/icons/icon-180x180.png',
    sizes: '180x180',
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/CooperBT/CooperBT-medium.woff2',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/Satoshi/Satoshi-Variable.woff2',
    crossOrigin: 'anonymous',
  },
];

export const handle = {
  i18n: 'common',
};

export async function loader(ctx: LoaderFunctionArgs) {
  const locale = await i18next.getLocale(ctx.request);
  const leadSource = new URL(ctx.request.url).searchParams.get('source');

  let viewerSession = await getViewerSession(ctx.request);
  const token = new URL(ctx.request.url).searchParams.get('token');
  const headers = new Headers();

  // Authenticate user by token
  if (
    !viewerSession &&
    token &&
    !['/confirm-email', '/reset-password', '/password-less'].includes(ctx.request.url)
  ) {
    try {
      const response = await authenticateByTokenMutation(ctx, {
        input: { token },
      });

      viewerSession = {
        ...response.authenticateByToken.viewer,
        token: response.authenticateByToken.token,
      };

      headers.append('Set-Cookie', await sessionStorage.commitSession(viewerSession));
    } catch {
      // Do nothing
    }
  }

  // Canonical
  const paginationSearchParams = new SearchParams<{ page?: number }>(ctx.request.url);
  const meta: any[] = [];

  if (process.env.NODE_ENV !== 'development') {
    if (paginationSearchParams.get('page', { asInteger: true }) != null) {
      paginationSearchParams.delete('page');

      const s = paginationSearchParams.toString().length
        ? '?' + paginationSearchParams.toString()
        : '';

      meta.push({
        tagName: 'link',
        rel: 'canonical',
        href: new URL(new URL(ctx.request.url).pathname + s, ctx.request.url).toString(),
      });
    } else {
      const _path = new URL(ctx.request.url).pathname;

      meta.push({
        tagName: 'link',
        rel: 'canonical',
        href: new URL(_path, ctx.request.url).toString(),
      });
    }
  }

  headers.append('Set-Cookie', await localeCookie.serialize(locale));
  if (leadSource) headers.append('Set-Cookie', await leadSourceCookie.serialize(leadSource));

  return dataResponse(
    {
      locale,
      ENV: getEnv(),
      viewer: viewerSession ? omit(viewerSession, 'token') : null,
      meta,
    },
    { status: 200, headers },
  );
}

const toasted: string[] = [];

function App() {
  const toasts = useRouteActionToasts();
  const data = useLoaderData<typeof loader>();
  const { t } = useTranslation(handle.i18n);

  // biome-ignore lint/correctness/useExhaustiveDependencies: we only want to run this effect once
  React.useEffect(() => {
    for (const _toast of toasts) {
      if (!toasted.includes(_toast.id)) {
        if (_toast.type === 'error') {
          console.error(_toast);

          toasted.push(_toast.id);
          toast.error(t('error-occured'), {
            description: _toast.error.message,
            duration: 8000,
          });
        }

        if (_toast.type === 'success') {
          toasted.push(_toast.id);
          toast.success(_toast.message, {
            description: _toast.description,
            duration: 4000,
          });
        }

        if (_toast.type === 'message') {
          toasted.push(_toast.id);
          toast(_toast.message, {
            description: _toast.description,
            duration: 1000,
          });
        }
      }
    }
  }, [toasts]);

  return <Outlet context={{ viewer: data.viewer }} />;
}

export default withSentry(App);

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  const { i18n } = useTranslation();
  const isMounted = useIsMounted();

  useChangeLanguage(data?.locale || 'fr');

  return (
    <html lang={data?.locale} dir={i18n.dir()} suppressHydrationWarning>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="smartbanner:title" content="Club Colette" />
        <meta name="smartbanner:author" content="Colette Club" />
        <meta name="smartbanner:price" content="Gratuit" />
        <meta name="smartbanner:price-suffix-apple" content=" sur l'App Store" />
        <meta name="smartbanner:price-suffix-google" content=" sur Google Play" />
        <meta name="smartbanner:icon-apple" content="/icons/icon-512x512.png" />
        <meta name="smartbanner:icon-google" content="/icons/icon-512x512.png" />
        <meta name="smartbanner:button" content="Ouvrir" />
        <meta name="smartbanner:button-url-apple" content={APPLE_STORE_LINK} />
        <meta name="smartbanner:button-url-google" content={GOOGLE_PLAY_STORE_LINK} />
        <meta name="smartbanner:enabled-platform:content" content="android,ios" />
        <meta name="smartbanner:close-label" content="Fermer" />
        <meta name="apple-itunes-app" content="app-id=6443635924" />
        <meta name="theme-color" content="#FFFDFA" />

        <Meta />
        <Links />
      </head>
      <body>
        <Toaster position="top-right" />

        {children}

        {data ? (
          <script
            // biome-ignore lint/security/noDangerouslySetInnerHtml: we trust the data
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
            }}
          />
        ) : null}
        <ScrollRestoration />
        <Scripts />

        {isMounted() ? <script src={smartbanner} async defer /> : null}

        {data?.ENV.GTM_ID ? <GoogleTagManager gtmId={data.ENV.GTM_ID} /> : null}
      </body>
    </html>
  );
}

const MOBILE_URLS = [
  /^\/activity-reviews$/,
  /^\/i\/activities\/([^/]+)\/thread$/,
  /^\/i\/threads\/([^/]+)$/,
  /^\/introduce-yourself$/,
  /^\/i\/onboarding\/1$/,
  /^\/i\/onboarding\/2$/,
  /^\/i\/onboarding\/3$/,
  /^\/i\/onboarding\/4$/,
  /^\/i\/onboarding\/5$/,
  /^\/i\/onboarding\/6$/,
  /^\/i\/profile\/update-interests$/,
  /^\/i\/start$/,
  /^\/i\/suggest-activity$/,
  /^\/i\/messages\/([^/]+)$/,
  /^\/update-app$/,
  /^\/i\/threads$/,
  /^\/i\/inbox$/,
  /^\/i\/media$/,
];

export function ErrorBoundary() {
  const { t } = useTranslation();
  const location = useLocation();
  const error = useRouteError();
  const fetcher = useFetcher<{ viewer: ViewerSession | null }>();

  const pathname = location.pathname.replace(/\/$/, '');

  const isMobileDeeplink =
    isRouteErrorResponse(error) &&
    error.status === 404 &&
    MOBILE_URLS.some((p) => p.test(pathname));

  // biome-ignore lint/correctness/useExhaustiveDependencies: we only want to run this effect once
  React.useEffect(() => {
    if (fetcher.state === 'idle' && !isMobileDeeplink) {
      fetcher.load('/api/viewer');
    }
  }, [isMobileDeeplink]);

  console.error(error);

  if (isMobileDeeplink) {
    return (
      <div className="grid h-screen px-4 lg:grid-cols-2 lg:px-0">
        <div className="mx-auto grid w-full max-w-[560px] place-items-center text-center">
          <div className="w-full">
            <div className="mb-10 space-y-4">
              <HeadingL>{t('mobile-only')}</HeadingL>
              <p
                className={textVariants({
                  size: 'm',
                  class: 'font-medium text-subdued-foreground',
                })}
              >
                {t('mobile-only-description')}
              </p>
            </div>
            <div className="flex items-center justify-center gap-4">
              <AppleStore className="h-12 md:h-14" />
              <GooglePlayStore className="h-12 md:h-14" />
            </div>
          </div>
        </div>

        <div className="hidden lg:block">
          <Image
            src="/images/mobile-only-cover.jpeg"
            className="h-full object-cover object-right-top"
          />
        </div>
      </div>
    );
  }

  captureRemixErrorBoundaryError(error);

  return (
    <>
      <Header>
        <Link to="/" prefetch="intent" aria-label="Club Colette">
          <Logo />
        </Link>

        <HeaderNav
          viewer={fetcher.data?.viewer}
          loading={fetcher.state === 'loading' || !fetcher.data}
        />
      </Header>

      <main className="mx-auto grid min-h-[60vh] max-w-4xl place-content-center place-items-center text-center">
        <HeadingXXL className="mb-14">
          {isRouteErrorResponse(error) && error.status === 404
            ? t('page-not-found')
            : error instanceof Error
              ? error.message
              : 'Une erreur est survenue'}
        </HeadingXXL>

        <Trans t={t} i18nKey="page-not-found-description">
          <HeadingM>
            Profitez-en pour venir découvrir les prochaines activités du Club Colette !
          </HeadingM>

          <p
            className={textVariants({
              size: 'l',
              class: 'mt-2 font-medium text-subdued-foreground',
            })}
          >
            De super activités vous attendent, ne tardez pas à réserver votre place ! Rendez-vous
            sur le planning !
          </p>
        </Trans>

        <Link
          to="/activites-senior"
          className={buttonVariants({ class: 'mt-8' })}
          prefetch="intent"
        >
          {t('page-not-found-explorer-next-activities')}
        </Link>
      </main>

      <Footer>
        <FooterContent>
          <FooterColette />
          <FooterServices />
          <FooterAboutColette />
          <FooterHelp />
        </FooterContent>

        <FooterMeta />
      </Footer>
    </>
  );
}
