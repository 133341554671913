import React from 'react';

export function GoogleTagManager(props: { gtmId: string }) {
  React.useEffect(() => {
    if (window.ENV.NODE_ENV === 'development') return;

    const gtmScript = document.createElement('script');

    gtmScript.innerHTML = `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${props.gtmId}');`;

    document.head.appendChild(gtmScript);

    return () => {
      document.head.removeChild(gtmScript);
    };
  }, [props.gtmId]);

  return (
    <>
      {/* GOOGLE TAG MANAGER NOSCRIPT */}
      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${props.gtmId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  );
}
